import { useEffect, useState } from "react";
import NavBar from "../../components/organisms/navbar";
import ProgressBar from "../../components/atoms/progress-bar";
import api from "../../services/api";
import {
  BR,
  Container,
  Content,
  PageLocationTitle,
  PageTitle,
  PageTitleContainer,
  TournamentActions,
  TournamentContainer,
  TournamentContent,
  TournamentDescription,
  TournamentImage,
  TournamentImageContent,
  TournamentItem,
  TournamentLink,
  TournamentList,
  TournamentTitle,
} from "./styles";

import { toast } from "react-toastify";
import useAuthenticate from "src/hooks/useAuthenticate";
import { Link } from "react-router-dom";

const Home = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthenticate();
  const slug = "xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am";
  const [tournament, setTournament] = useState<any>({});

  useEffect(() => {
    setLoading(true);
    api
      .get("/tournaments/" + slug)
      .then(({ data }) => {
        setTournament(data);
      })
      .catch(() => {
        toast.error("Não foi carregar os dados do torneio.");
      });

    api
      .get("/tournaments/enrollments/list-all/public/" + slug, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        const sortedArray = data.sort((a: any, b: any) => a.id - b.id);
        setData(sortedArray);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi carregar os dados das inscrições.");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredArrayStatusTrue = data.filter((value: any) => value.status === true);

  //valor estatico

  const filteredArrayStatusFalse = data.filter((value: any) => value.status === false);

  const calcStatusTrue = (filteredArrayStatusTrue.length / 1300) * 100;
  //const calcStatusTrue = 102.27;
  const calcStatusTrue1 = 102.27;

  let fakeProgress = 0;

  if (calcStatusTrue < 60) {
    if (calcStatusTrue + 15 > 60) {
      let diff = 60 - calcStatusTrue;
      fakeProgress = calcStatusTrue + diff;
    } else {
      fakeProgress = calcStatusTrue + 15;
    }
  } else if (calcStatusTrue >= 60) {
    fakeProgress = 0;
  }

  return (
    <>
    <div translate="no">
      <NavBar />
      <Container>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>TORNEIOS</PageLocationTitle>
            <PageTitle>27 torneios encontrados</PageTitle>
          </PageTitleContainer>
          <TournamentContainer>
            <TournamentList>



              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/i-open-laranjeiras-do-sul-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/i-open-laranjeiras-do-sul-de-jiu-jitsu/i-open-laranjeiras-do-sul-de-jiu-jitsu.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    {"1º Open Laranjeiras do Sul de Jiu Jitsu".toUpperCase()}
                  </TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span>
                      <b>LOTE 1 - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 100,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 30/10/2024</span>
                    <BR />
                    <span>
                      <b>LOTE 2 - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 120,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 12/11/2024</span>
                    <BR />
                    <span>
                      {" "}
                      <b>LOTE 3 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 130,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 14/11/2024</span>
                    <br />
                    <br />
                    <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    <br />
                    <br />
                    <strong style={{ color: "red" }}>
                      NÃO HAVERÁ LUTAS CASADA NESSE EVENTO
                    </strong>

                    {/* <strong style={{ color: "red" }}>As pessoas com deficiência (PCD) devem informar, no momento da inscrição, sua condição pelo WhatsApp."</strong> */}
                    {/* <strong style={{ color: "red" }}>
                      As pessoas com deficiência (PCD) devem informar, no momento da inscrição, sua condição pelo WhatsApp.
                    </strong> */}
                    {/* <div style={{ textAlign: "justify" }}>
                      <strong style={{ color: "red" }}>
                        As pessoas com deficiência (PCD) devem informar, no momento da
                        inscrição, sua condição pelo WhatsApp.
                      </strong>
                    </div> */}
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/i-open-laranjeiras-do-sul-de-jiu-jitsu">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>





            

              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/estadual-gf-team-amazonas-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/estadual-gf-team-amazonas-jiu-jitsu/estadual-gf-team-amazonas-jiu-jitsu.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    {"Estadual GF Team Amazonas Jiu Jitsu GI - NOGI".toUpperCase()}
                  </TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span>
                      <b>INSCRIÇÃO - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 110,00 COMBO CATEGORIA GI+NOGI</span>
                    <BR />
                    <span>
                      <b>INSCRIÇÃO - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 70,00 CATEGORIA GI</span>
                    <br />

                    <span>R$ 50,00 CATEGORIA NOGI</span>
                    <BR />

                    <span><b>PAGAMENTO ATÉ 03/12/2024</b></span>
                    <BR />

                  
                    <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    <BR />
             
                    <strong style={{ color: "red" }}>
                      NÃO HAVERÁ LUTAS CASADA NESSE EVENTO
                    </strong>

                    {/* <strong style={{ color: "red" }}>As pessoas com deficiência (PCD) devem informar, no momento da inscrição, sua condição pelo WhatsApp."</strong> */}
                    {/* <strong style={{ color: "red" }}>
                      As pessoas com deficiência (PCD) devem informar, no momento da inscrição, sua condição pelo WhatsApp.
                    </strong> */}
                    {/* <div style={{ textAlign: "justify" }}>
                      <strong style={{ color: "red" }}>
                        As pessoas com deficiência (PCD) devem informar, no momento da
                        inscrição, sua condição pelo WhatsApp.
                      </strong>
                    </div> */}
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/estadual-gf-team-amazonas-jiu-jitsu">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/iii-open-rocha-e-moraes-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/iii-open-rocha-e-moraes-de-jiu-jitsu/iii-open-rocha-e-moraes-de-jiu-jitsu.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    {"III Open Rocha e Moraes".toUpperCase()}
                  </TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span>
                      <b>LOTE 1 - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 50,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 29/11/2024</span>
                    <BR />
                    <span>
                      {" "}
                      <b>LOTE 2 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 60,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 10/12/2024</span>
                    <br />
                    <br />
                    <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    <br />
                    <span>GRÁTIS PARA OS MEDALHISTAS DAS CATEGORIAS</span>

                    {/* <strong style={{ color: "red" }}>As pessoas com deficiência (PCD) devem informar, no momento da inscrição, sua condição pelo WhatsApp."</strong> */}
                    {/* <strong style={{ color: "red" }}>
                      As pessoas com deficiência (PCD) devem informar, no momento da inscrição, sua condição pelo WhatsApp.
                    </strong> */}
                    {/* <div style={{ textAlign: "justify" }}>
                      <strong style={{ color: "red" }}>
                        As pessoas com deficiência (PCD) devem informar, no momento da
                        inscrição, sua condição pelo WhatsApp.
                      </strong>
                    </div> */}
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/iii-open-rocha-e-moraes-de-jiu-jitsu">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              <TournamentItem >
                <TournamentImageContent>
                  <Link to="/tournament/xxix-copa-luis-neto-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/xxix-copa-luis-neto-jiu-jitsu/xxix-copa-luis-neto-jiu-jitsu.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    {"29º Copa Luis Neto de Jiu Jitsu GI e NOGI".toUpperCase()}
                  </TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span>
                      <b>INSCRIÇÃO - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 90,00 COMBO CATEGORIA GI+NOGI</span>
                    <br />
                    <BR />
                    <span>
                      <b>INSCRIÇÃO - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 50,00 CATEGORIA GI</span>
                    <br />

                    <span>R$ 50,00 CATEGORIA NOGI</span>
                    <BR />
                    <span> <b>Pagamento até 05/11/2024 (TERÇA-FEIRA</b>)</span>
                    <BR />

                    <br />
                    <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO </strong>
                    <br />
                    <br />
                    <strong style={{ color: "red" }}>
                      NÃO HAVERÁ LUTAS CASADA NESSE EVENTO
                    </strong>

                    {/* <strong style={{ color: "red" }}>As pessoas com deficiência (PCD) devem informar, no momento da inscrição, sua condição pelo WhatsApp."</strong> */}
                    {/* <strong style={{ color: "red" }}>
                      As pessoas com deficiência (PCD) devem informar, no momento da inscrição, sua condição pelo WhatsApp.
                    </strong> */}
                    {/* <div style={{ textAlign: "justify" }}>
                      <strong style={{ color: "red" }}>
                        As pessoas com deficiência (PCD) devem informar, no momento da
                        inscrição, sua condição pelo WhatsApp.
                      </strong>
                    </div> */}
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink 
                  to="/tournament/xxix-copa-luis-neto-jiu-jitsu"
                  disabled
                  >
                    Inscrição encerrada
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>

              

              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/ii-copa-champions-kids-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/ii-copa-champions-kids-de-jiu-jitsu/ii-copa-champions-kids-de-jiu-jitsu.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    {"2° Copa Champions Kids de Jiu Jitsu".toUpperCase()}
                  </TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span>
                      <b>LOTE 1 - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 100,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 09/10/2024</span>
                    <BR />
                    <span>
                      {" "}
                      <b>LOTE 2 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 130,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 16/10/2024</span>
                    <br />
                    <br />
                    <strong>NÃO HAVERÁ INSCRIÇÃO PARA ABSOLUTO NESTE EVENTO</strong>
                    <br />
                    <br />
                    <strong style={{ color: "red" }}>
                      NÃO HAVERÁ LUTAS CASADA NESSE EVENTO
                    </strong>
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink
                    disabled
                    to="/tournament/ii-copa-champions-kids-de-jiu-jitsu"
                  >
                    Inscrição encerrada
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/copa-cianorte-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/copa-cianorte-de-jiu-jitsu/copa-cianorte-de-jiu-jitsu.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    {"Copa Cianorte de Jiu Jitsu".toUpperCase()}
                  </TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span>
                      <b>LOTE 1 - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 120,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 10/09/2024</span>
                    <BR />
                    <span>
                      {" "}
                      <b>LOTE 2 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 130,00 CATEGORIA(PRORROGADO)</span>
                    <br />
                    <span>Pagamento até 19/09/2024</span>
                    <br />
                    <br />
                    <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    {/* <br />
                    <br />
                    <strong style={{ color: "red" }}>NÃO HAVERÁ LUTAS CASADA NESSE EVENTO</strong> */}
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink disabled to="/tournament/copa-cianorte-de-jiu-jitsu">
                    Inscrição encerrada
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/i-orixicombat-open-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/i-orixicombat-open-jiu-jitsu/i-orixicombat-open-jiu-jitsu.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>I ORIXICOMBAT OPEN JIU JITSU</TournamentTitle>
                  <TournamentDescription>
                    <span>GI R$ 70.00</span>
                    <br />
                    <br />
                    {/* <strong>INSCRIÇÕES LIMITADAS</strong>                    
                    <br />                    
                    <span>APENAS 750 INSCRIÇÕES</span>                                   
                  Sem fake */}
                    {/* <ProgressBar bgcolor="orange" progress={calcStatusTrue} height={30} />  */}
                    {/* Com fake */}
                    {/* <ProgressBar bgcolor="orange" progress={fakeProgress > 0 ? fakeProgress : calcStatusTrue} height={30} /> */}
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink disabled to="/tournament/i-orixicombat-open-jiu-jitsu">
                    Inscrição encerrada
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/vi-rei-da-praia-de-jiu-jitsu-no-gi">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/vi-rei-da-praia-de-jiu-jitsu-no-gi/vi-rei-da-praia-de-jiu-jitsu-no-gi.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    {"VI Rei da Praia No Gi 2024".toUpperCase()}
                  </TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span>
                      <b>LOTE 1 - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 70,00 CATEGORIA</span>
                    <br />
                    <span>R$ 45,00 ABSOLUTO</span>
                    <br />
                    <span>Pagamento até 15/09/2024</span>
                    <BR />
                    <span>
                      {" "}
                      <b>LOTE 2 - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 80,00 CATEGORIA</span>
                    <br />
                    <span>R$ 50,00 ABSOLUTO</span>
                    <br />
                    <span>Pagamento até 25/09/2024</span>
                    <BR />
                    <span>
                      {" "}
                      <b>LOTE 3 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 90,00 CATEGORIA</span>
                    <br />
                    <span>R$ 60,00 ABSOLUTO</span>
                    <br />
                    <span>Pagamento até 30/09/2024</span>
                    <br />
                    <br />
                    {/* <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong> */}
                    <br />
                    <br />
                    <strong style={{ color: "red" }}>
                      NÃO HAVERÁ LUTAS CASADA NESSE EVENTO
                    </strong>
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink
                    disabled
                    to="/tournament/vi-rei-da-praia-de-jiu-jitsu-no-gi"
                  >
                    Inscrição encerrada
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/vii-open-amazonas-top-team-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/vii-open-amazonas-top-team-de-jiu-jitsu/vii-open-amazonas-top-team-de-jiu-jitsu.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    {"VII Open Amazonas Top Team de Jiu Jitsu GI".toUpperCase()}
                  </TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span>
                      <b>LOTE 1 - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 40,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 31/07/2024</span>
                    <BR />
                    <span>
                      {" "}
                      <b>LOTE 2 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 50,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 31/08/2024</span>
                    <br />
                    <span>
                      {" "}
                      <b>LOTE 3 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 60,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 10/09/2024</span>
                    <br />
                    <br />
                    <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    <br />
                    <span>R$ 40,00 ABSOLUTO</span>
                    <br />
                    <br />
                    <strong style={{ color: "red" }}>
                      NÃO HAVERÁ LUTAS CASADA NESSE EVENTO
                    </strong>
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink
                    disabled
                    to="/tournament/vii-open-amazonas-top-team-de-jiu-jitsu"
                  >
                    Inscrição encerrada
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/copa-lobo-bravo-cresol-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/copa-lobo-bravo-cresol-de-jiu-jitsu/copa-lobo-bravo-cresol-de-jiu-jitsu.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    {"COPA LOBO BRAVO CRESOL DE JIU JITSU".toUpperCase()}
                  </TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span>
                      <b>LOTE 1 - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 100,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 24/08/2024</span>
                    <BR />
                    <span>
                      {" "}
                      <b>LOTE 2 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 120,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 27/08/2024</span>
                    <br />
                    <br />
                    <strong>INSCRIÇÃO PARA ABSOLUTO ADULTO JÁ ESTA ABERTA</strong>
                    <br />
                    <br />
                    <strong>INSCRIÇÃO ABSOLUTO JUVENIL SOMENTE NO DIA DO EVENTO</strong>
                  </TournamentDescription>
                </TournamentContent>



                <TournamentActions>
                  <TournamentLink
                    disabled
                    to="/tournament/copa-lobo-bravo-cresol-de-jiu-jitsu"
                  >
                    Inscrição encerrada
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/ii-copa-arena-parintins-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/ii-copa-arena-parintins-de-jiu-jitsu/ii-copa-arena-parintins-de-jiu-jitsu-2024.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>II COPA ARENA PARINTINS DE JIU JITSU</TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span>
                      <b>LOTE 1 - (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 65,00 CATEGORIA GI</span>
                    <br />
                    <span>R$ 65,00 ABSOLUTO GI</span>
                    <br />
                    <span>R$ 65,00 CATEGORIA NOGI</span>
                    <br />
                    <span>R$ 65,00 ABSOLUTO NOGI</span>
                    <br />
                    <span>Pagamento até 30/07/2024</span>
                    <BR />
                    <span>
                      <b>LOTE 2 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 75,00 CATEGORIA GI</span>
                    <br />
                    <span>R$ 75,00 ABSOLUTO GI</span>
                    <br />
                    <span>R$ 75,00 CATEGORIA NOGI</span>
                    <br />
                    <span>R$ 75,00 ABSOLUTO NOGI</span>
                    <br />
                    <span>Pagamento até 27/08/2024</span>
                    <br />
                    <br />
                    <br />
                    <strong>INSCRIÇÕES LIMITADAS</strong>
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink
                    disabled
                    to="/tournament/ii-copa-arena-parintins-de-jiu-jitsu"
                  >
                    Encerrado
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/copa-fronteira-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/copa-fronteira-de-jiu-jitsu/copa-fronteira-de-jiu-jitsu.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    {"Copa Fronteira de Jiu Jitsu".toUpperCase()}
                  </TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    {/* <span><b>LOTE 1 - (PREÇO PROMOCIONAL)</b> </span>
                    <br />
                    <span>R$ 120,00 CATEGORIA</span>
                    <span>Pagamento até 12/08/2024</span> */}
                    <BR />
                    <span style={{ color: "red" }}>
                      <b>LOTE 1 - (ENCERRADO)</b>{" "}
                    </span>
                    <br />

                    <span>
                      {" "}
                      <b>LOTE 2 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 130,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 21/08/2024</span>
                    <br />
                    <br />
                    <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    <br />
                    <br />
                    <strong style={{ color: "red" }}>
                      NÃO HAVERÁ LUTAS CASADA NESSE EVENTO
                    </strong>
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink disabled to="/tournament/copa-fronteira-de-jiu-jitsu">
                    Encerrado
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/xxxii-copa-orley-lobato-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/xxxii-copa-orley-lobato-de-jiu-jitsu/xxxii-copa-orley-lobato-de-jiu-jitsu.png")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>XXXII COPA ORLEY LOBATO DE JIU JITSU</TournamentTitle>
                  <TournamentDescription>
                    <BR />
                    <span style={{ color: "red" }}>
                      <b>LOTE 1 - (FINALIZADO)</b>{" "}
                    </span>
                    {/* <br />
                    <span>R$ 50,00 CATEGORIA</span>
                    <br />
                    <span>R$ 30,00 ABSOLUTO</span>
                    <br />
                    <span>Até 05/07/2024</span> */}
                    <BR />
                    <span>
                      <b>LOTE 2 - (PREÇO NORMAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 70,00 CATEGORIA</span>
                    <br />
                    <span>R$ 40,00 ABSOLUTO</span>
                    <br />
                    <span>Até 05/08/2024</span>
                    <br />
                    <br />
                    <br />
                    <strong>INSCRIÇÕES LIMITADAS</strong>
                    <BR />
                    <span>APENAS 1300 INSCRIÇÕES</span>
                    {/* Sem fake */}
                    {/* <ProgressBar bgcolor="orange" progress={calcStatusTrue} height={30} /> */}
                    {/* Com fake */}
                    {/* * <ProgressBar bgcolor="orange" progress={fakeProgress > 0 ? fakeProgress : calcStatusTrue} height={30} /> */}
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink
                    disabled
                    to="/tournament/xxxii-copa-orley-lobato-de-jiu-jitsu"
                  >
                    Encerrado
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>



              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/i-copa-black-belt-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/i-copa-black-belt-de-jiu-jitsu/i-copa-black-belt-de-jiu-jitsu-2024.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                <TournamentTitle>{'I Copa Black Belt de Jiu Jitsu'.toUpperCase()}</TournamentTitle>
                  <TournamentDescription>
                  <BR />
                    <span><b>LOTE 1 - (PREÇO PROMOCIONAL)</b> </span>
                    <br />
                    <span>R$ 100,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 13/09/2024</span>
                    <BR/>
                    <span> <b>LOTE 2 - (PREÇO NORMAL)</b> </span>
                    <br />
                    <span>R$ 130,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 25/09/2024</span>
                    <br />
                    <br />
                    <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO PARA OS 4 PRIMEIROS COLOCADOS DAS CATEGORIAS</strong>
                  </TournamentDescription>
                </TournamentContent>

                <TournamentActions>
                  <TournamentLink to="/tournament/i-copa-black-belt-de-jiu-jitsu">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/ii-rei-e-rainha-da-praia-de-beach-boxing/ii-rei-e-rainha-da-praia-de-beach-boxing.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    II REI DA PRAIA - BEACH BOXING
                  </TournamentTitle>
                  <TournamentDescription>
                  <span><b>LOTE 1 - (PREÇO PROMOCIONAL)</b> </span>
                    <br />
                    <span>R$ 90,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 04/07/2024</span>
                    <BR/>
                    <span> <b>LOTE 2 - (PREÇO NORMAL)</b> </span>
                    <br />
                    <span>R$ 120,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 17/07/2024</span>
                    <br />
                    <br />

                    {/* <span>
                      {" "}
                      <b>INSCRIÇÕES E CORREÇÕES PRORROGADAS</b>{" "}
                    </span>
                    <br />
                    <span style={{ color: "red" }}>
                      {" "}
                      <b>ATÉ 22H DO DIA 12/10/2023</b>
                    </span> */}
              {/* <br />
                    <br /> */}
              {/* <strong>INSCRIÇÕES LIMITADAS</strong>
                    <br />
                    <span>APENAS 750 INSCRIÇÕES</span> */}
              {/* Sem fake */}
              {/* <ProgressBar
                      bgcolor="orange"
                      progress={calcStatusTrue}
                      height={30}
                    /> */}
              {/* Com fake */}
              {/* <ProgressBar bgcolor="orange" progress={fakeProgress > 0 ? fakeProgress : calcStatusTrue} height={30} /> */}
              {/* </TournamentDescription> */}
              {/* </TournamentContent>  */}

              {/* <TournamentActions>
                  <TournamentLink to="/tournament/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/iv-open-terra-santa-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/iv-open-terra-santa-de-jiu-jitsu/iv-open-terra-santa-de-jiu-jitsu.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>IV OPEM TERRA SANTA DE JIU JITSU</TournamentTitle>
                  <TournamentDescription>
                    <br />
                    <span><b>GI (COM KIMONO) </b></span>
                    <br />
                    <span>VALOR R$ 70,00</span>
                    <BR />
                    <span><b>NO GI (SEM KIMONO) </b></span>
                    <br />
                    <span>VALOR R$ 70,00</span>
                    <BR />
                    <span><b>COMBO GI + NO GI  </b></span>
                    <br />
                    <span>VALOR R$ 120,00</span>
                    <BR />
                    <span><strong>ABSOLUTO GI ou NOGI</strong></span>
                    <br />
                    <span>VALOR R$ 70,00</span>
                    <br />
                    <span>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</span>
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/iv-open-terra-santa-de-jiu-jitsu">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/i-copa-thomasi-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/i-copa-thomasi-de-jiu-jitsu/i-copa-thomasi-de-jiu-jitsu.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                <TournamentTitle>{'I Copa Thomasi de Jiu Jitsu'.toUpperCase()}</TournamentTitle>
                  <TournamentDescription>
                  <BR />
                    <span><b>LOTE 1 - (PREÇO PROMOCIONAL)</b> </span>
                    <br />
                    <span>R$ 100,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 30/06/2024</span>
                    <BR/>
                    <span> <b>LOTE 2 - (PREÇO NORMAL)</b> </span>
                    <br />
                    <span>R$ 130,00 CATEGORIA</span>
                    <br />
                    <span>Pagamento até 10/07/2024</span>
                    <br />
                    <br />
                    <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                  </TournamentDescription>
                </TournamentContent>

                <TournamentActions>
                  <TournamentLink to="/tournament/i-copa-thomasi-de-jiu-jitsu">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/xvii-copa-parana-de-jiu-jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/xvii-copa-parana-de-jiu-jitsu/xvii-copa-parana-de-jiu-jitsu.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>XVII COPA PARANÁ DE JIU JITSU</TournamentTitle>
                  <TournamentDescription>
                    <br />
                    <span><b>LOTE 1 - R$ 100,00 (GI) </b></span>
                    <br />
                    <span>Pagamento até 26/05/2024</span>
                    <BR />
                    <span><b>LOTE 2 - R$ 120,00 (GI) </b></span>
                    <br />
                    <span>Pagamento até 06/06/2024</span>
                    <BR />
                    <span style={{ color: "red" }}><b>LOTE 3 - R$ 130,00 (GI) - PRORROGADO </b></span>
                    <br />
                    <span style={{ color: "red" }}>Pagamento até 12/06/2024</span>
                    <BR />
                    <span style={{ color: "red" }}><b>LOTE UNICO - R$ 100,00 (NOGI) - PRORROGADO  </b></span>
                    <br />
                    <span style={{ color: "red" }}>Até 12/06/2024</span>
                    <br />
                    <br />
                    <span>
                      <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    </span>
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/xvii-copa-parana-de-jiu-jitsu">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/copa-mestre-acai-de-jiu-jitsu-2024">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/copa-mestre-acai-de-jiu-jitsu-2024/copa-mestre-acai-de-jiu-jitsu-2024.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>COPA MESTRE AÇAI DE JIU JITSU 2024</TournamentTitle>
                  <TournamentDescription>
                    <br />
                    <span><b>LOTE 1 - R$ 120,00 (FINALIZADO) </b></span>
                    <br />
                    <span>Até 30/04/2024</span>
                    <BR />
                    <span><b>LOTE 2 - R$ 130,00 (FINALIZADO) </b></span>
                    <br />
                    <span>Até 05/05/2024</span>
                    <BR />
                    <span style={{ color: "red" }}><b>LOTE 3 - R$ 140,00(PRORROGADO) </b></span>
                    <br />
                    <span style={{ color: "red" }}>Até 15/05/2024 AS 15 HORAS</span>
                    <br />
                    <br />
                    <span>
                      <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    </span>
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/copa-mestre-acai-de-jiu-jitsu-2024">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/copa-amizade-de-jiu-jitsu-2024.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>COPA AMIZADE DE JIU JITSU 2024</TournamentTitle>
                  <TournamentDescription>
                    <span>GI R$ 120.00</span>
                    <br />
                    <br />

                    <span>
                      {" "}
                      <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    </span>
               </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/seletiva-manaus-olimpica-2024">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/seletiva-manaus-olimpica-2024/banner-seletiva-manaus-olimpica-2024.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    SELETIVA MANAUS OLÍMPICA 2024
                  </TournamentTitle>
                  <TournamentDescription>
                    <br />
                    <span>INSCRIÇÃO GRATUITA</span>
                    <br />
                    <br />
                    <span>OBS: Inscrição permitida apenas para moradores de Manaus-AM</span>
                    <br />
                    <br />

                    <span>
                      {" "}
                      <b>INSCRIÇÕES E CORREÇÕES PRORROGADAS</b>{" "}
                    </span>
                    <br />
                    <span style={{ color: "red" }}>
                      {" "}
                      <b>ATÉ 22H DO DIA 25/04/2024</b>
                    </span>
                    <br />
                    <br />
                </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink style={{backgroundColor: 'red'}} to="/tournament/seletiva-manaus-olimpica-2024">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024/viii-rei-e-rainha-da-praia--beach-wrestiling-manaus-amazonas-2024.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    VIII REI E RAINHA DA PRAIA - BEACH WRESTLING 2024
                  </TournamentTitle>
                  <TournamentDescription>
                    <span>KIDS R$ 50.00</span>
                    <br />
                    <span>JUVEIL/ADULTO/MASTER R$ 70.00</span>
                    <br />
                    <br />
                  </TournamentDescription>
                </TournamentContent>

                <TournamentActions>





                  <TournamentLink to="/tournament/viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>
              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/open-guaraniacu-brazilian-jiu-jitsu-2024-ginasio-de-esportes-onofre-garbasheski-guaraniacu-pr">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/open-guaraniacu-jiu-jitsu-2024/open-guaraniacu-jiu-jitsu-2024.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>OPEN GUARANIAÇU JIU JITSU 2024</TournamentTitle>
                  <TournamentDescription>
                    <span>GI R$ 100.00</span>
                    <br />
                    <br />
                    <span>
                      {" "}
                      <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    </span>
                    <br />
                  </TournamentDescription>
                </TournamentContent>

                <TournamentActions>
                  <TournamentLink to="/tournament/open-guaraniacu-brazilian-jiu-jitsu-2024-ginasio-de-esportes-onofre-garbasheski-guaraniacu-pr">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>

              <TournamentItem>
                <TournamentImageContent>
                  <Link to="#">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    I REI E RAINHA DA PRAIA - BEACH BOXING
                  </TournamentTitle>
                  <TournamentDescription>
                    <span>CATEGORIA R$ 90.00</span>
                    <br />
                    <br />
                  </TournamentDescription>
                </TournamentContent>

                <TournamentActions>
                  <TournamentLink to="#">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/1º-Copa-Arena-Parintins-de-Jiu-Jitsu">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/1-copa-arena-parintins/1-copa-arena-parintins.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>1º COPA ARENA PARINTINS DE JIU JITSU</TournamentTitle>
                  <TournamentDescription>
                    <b>
                      {" "}
                      <span>INSCRIÇÃO</span>
                    </b>
                    <br />
                    <span>1º LOTE ATÉ 10/11/2023</span>
                    <br />
                    <span>VALOR R$ 60.00</span>
                    <br />
                    <br />
                    <span>LOTE FINAL ATÉ 20/11/2023</span>
                    <br />
                    <span>VALOR R$ 70.00</span>
                  </TournamentDescription>
                </TournamentContent>

                <TournamentActions>
                  <TournamentLink to="/tournament/1º-Copa-Arena-Parintins-de-Jiu-Jitsu">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>

              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/toledo-international-open-brazilian-jiu-jitsu-2023">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/toledo-international-open-brazilian-jiu-jitsu-2023.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>TOLEDO INTERNATIONAL OPEN GI E NO GI</TournamentTitle>
                  <TournamentDescription>
                    <span>GI R$ 100.00</span>
                    <br />
                    <span>NO GI R$ 100.00</span>
                    <br />
                    <span>GI + NO GI R$ 150.00</span>
                    <br />
                    <br />

                    <span>
                      {" "}
                      <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    </span>
                    <br />
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/toledo-international-open-brazilian-jiu-jitsu-2023">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/VII-REI-DA-PRAIA---BEACH-WRESTLING">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/vii-rei-da-praia-beach-wrestiling/vii-rei-da-praia-beach-wrestiling.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    VII REI E RAINHA DA PRAIA - BEACH WRESTLING
                  </TournamentTitle>
                  <TournamentDescription>
                    <span>CATEGORIA R$ 50.00</span>
                    <br />
                    <br />
                    <span>
                      {" "}
                      <b>INSCRIÇÕES E CORREÇÕES PRORROGADAS</b>{" "}
                    </span>
                    <br />
                    <span style={{ color: "red" }}>
                      {" "}
                      <b>ATÉ 22H DO DIA 05/10/2023</b>
                    </span>
                    <br />
                    <br />
                  </TournamentDescription>
                </TournamentContent>

                <TournamentActions>
                  <TournamentLink to="/tournament/VII-REI-DA-PRAIA---BEACH-WRESTLING">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* 
              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/INTERNATIONAL-CIRCUIT">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/international-circuit/international-circuit.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>
                    INTERNATIONAL CIRCUIT GI E NO GI DE JIU-JITSU
                  </TournamentTitle>
                  <TournamentDescription>
                    <span>GI R$ 100.00</span>
                    <br />
                    <span>NO GI R$ 100.00</span>
                    <br />
                    <span>GI + NO GI R$ 150.00</span>
                    <br />
                    <br />

                    <span>
                      {" "}
                      <strong>INSCRIÇÃO ABSOLUTO SOMENTE NO DIA DO EVENTO</strong>
                    </span>
                    <br />
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/INTERNATIONAL-CIRCUIT">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* 
              <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/ESTADUAL-GFTEAM-AMAZONAS">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/gfteam-amazonas/gfteam-amazonas.jpeg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>ESTADUAL GFTEAM AMAZONAS DE JIU-JITSU</TournamentTitle>
                  <TournamentDescription>
                    <span>INSCRIÇÃO R$ 60,00</span>
                    <br />
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/ESTADUAL-GFTEAM-AMAZONAS">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <Link to="/tournament/XXXI-COPA-ORLEY-LOBATO-DE-JIU-JITSU">
                    <TournamentImage
                      src={require("../../assets/images/tournaments/xxxi-copa-orley-lobato/xxxi-copa-orley-lobato.jpg")}
                    />
                  </Link>
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>XXXI COPA ORLEY LOBATO DE JIU-JITSU</TournamentTitle>
                  <TournamentDescription>
                    <span>CATEGORIA R$ 50.00</span>
                    <br />
                    <span>ABSOLUTO R$ 30.00</span>
                    <br />
                    <br />
                    <strong>INSCRIÇÕES LIMITADAS</strong>
                    <br />
                    <span>APENAS 750 INSCRIÇÕES</span> */}
              {/* Sem fake */}
              {/* <ProgressBar bgcolor="orange" progress={calcStatusTrue1} height={30} /> */}
              {/* Com fake */}
              {/* <ProgressBar bgcolor="orange" progress={fakeProgress > 0 ? fakeProgress : calcStatusTrue} height={30} /> */}
              {/* </TournamentDescription>
                </TournamentContent>

                <TournamentActions>
                  <TournamentLink to="/tournament/XXXI-COPA-ORLEY-LOBATO-DE-JIU-JITSU">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <TournamentImage
                    src={require("../../assets/images/tournaments/xxvi-copa-parana/copa-parana.jpeg")}
                  />
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>16º COPA PARANÁ DE JIU-JITSU</TournamentTitle>
                  <TournamentDescription>
                    <span>INSCRIÇÃO</span>
                    <br />
                    <span>1º LOTE R$ 100.00</span>
                    <br />
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/16-COPA-PARANA-DE-JIU-JITSU">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/* <TournamentItem>
                <TournamentImageContent>
                  <TournamentImage
                    src={require("../../assets/images/tournaments/III-open-terra-santa/campeonato-01.jpeg")}
                  />
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>III OPEN TERRA SANTA DE JIU-JITSU</TournamentTitle>
                  <TournamentDescription>
                    <span>INSCRIÇÃO</span>
                    <br />
                    <span>1º LOTE R$ 60.00</span>
                    <br />
                    <span>2º LOTE R$ 70.00</span>
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink to="/tournament/III-OPEN-TERRA-SANTA-DE-JIU-JITSU">
                    Saiba mais
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem>
              <TournamentItem>
                <TournamentImageContent>
                  <TournamentImage
                    src={require("../../assets/images/tournaments/xxxi-copa-orley-lobato/campeonato-02.jpeg")}
                  />
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>XXX COPA ORLEY LOBATO DE JIU-JITSU</TournamentTitle>
                  <TournamentDescription>
                    <span>INSCRIÇÃO</span>
                    <br />
                    <span>R$ 50.00</span>
                  </TournamentDescription>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink disabled to="#">
                    Encerrado
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}

              {/*               
              <TournamentItem>
                <TournamentImageContent>
                  <TournamentImage
                    src={require("../../assets/images/tournaments/vi-back-belt-confrontation/campeonato-03.jpeg")}
                  />
                </TournamentImageContent>
                <TournamentContent>
                  <TournamentTitle>VI BACK BELT CONFRONTATION</TournamentTitle>
                </TournamentContent>
                <TournamentActions>
                  <TournamentLink disabled to="#">
                    Encerrado
                  </TournamentLink>
                </TournamentActions>
              </TournamentItem> */}
            </TournamentList>
          </TournamentContainer>
        </Content>
      </Container>
      </div>
    </>
  );
};

export default Home;
